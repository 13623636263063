<template>
    <layout :active="70">
        <div class="content-body">
            <div class="mb-80">
                <div class="container h-100">
                    <div class="row justify-content-center h-100 align-items-center">
                        <div class="col-xl-12 col-md-12">
                            <div class="row">
                                <div class="col-xl-8 col-lg-12 col-md-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">Reportes</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="buy-sell-widget">
                                                <div class="tabs tab-content tab-content-default">
                                                    <div class="tab-content">
                                                        <div role="tabpanel" class="tab-pane active">
                                                            <div class="buy-sell-widget px-2">
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">Selecciona el reporte</label>
                                                                    <div class="input-group mb-3">
                                                                        <div class="input-group-prepend">
                                                                            <label class="input-group-text">
                                                                                <i class="fa fa-chart-line"></i>
                                                                            </label>
                                                                        </div>
                                                                        <select name="currency" class="form-control" v-model="reportSelected">
                                                                            <option value="">-- Seleccione --</option>
                                                                            <option value="orders" v-if="Access('reportes-reportes-ordenes')">Listado de ordenes</option>
                                                                            <option value="products" v-if="Access('reportes-reportes-productos')">Productos en sistema</option>
                                                                            <option value="citas" v-if="Access('reportes-reportes-citas')">Listado de citas</option>
                                                                            <option value="personal" v-if="Access('reportes-reportes-personal')">Usuarios adicionales o personal</option>
                                                                            <option value="suscripciones" v-if="Access('reportes-reportes-suscripciones')">Suscripciones</option>
                                                                            <option value="cotizaciones" v-if="Access('reportes-reportes-suscripciones')">Cotizaciones</option>
                                                                            <option value="smsEnviados" v-if="Access('reportes-reportes-smsEnviados')">SMS enviados</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group" v-if="(reportSelected === 'orders' || reportSelected === 'citas'|| reportSelected === 'suscripciones' || reportSelected === 'cotizaciones'|| reportSelected === 'smsEnviados')">
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <label>Fecha Desde</label>
                                                                            <date-picker v-model="dateRange.start"/>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <label>Fecha Hasta</label>
                                                                            <date-picker v-model="dateRange.end"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<p class="p-4"> Note: Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi cupiditate suscipit explicabo voluptas eos in tenetur error temporibus dolorum. Nulla! </p>-->
                                </div>
                                <div class="col-xl-4 col-lg-12 col-md-12">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <h6>Seleccione el reporte y las fechas para su generación</h6>
                                            <hr>
                                            <div class="text-muted">
                                                Para descargar un reporte, debe permitir las ventanas emergentes en su navegador
                                            </div>
                                            <div class="mt-3">
                                                <button @click="generateReport" class="btn btn-success btn-block mt-2"><i class="fas fa-eye mr-2"></i>Ver reporte</button>
                                            </div>
                                            <div class="mt-1">
                                                <button @click="generateReport(1)" class="btn btn-primary btn-block mt-2"><i class="fas fa-download mr-2"></i>Descargar reporte</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" v-if="results">
                        <div class="card-body text-center">
                            <div class="reportResult">
                                <table class="table table-striped">
                                    <tr v-for="item in results" :key="item">
                                        <td v-for="value in item" :key="value">
                                            {{value}}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";
import {store} from 'src/store'
import DataValidation from "src/core/DataValidation";
import {DatePicker} from 'v-calendar';
import dayjs from "dayjs";


export default {
    components: {
        Layout,
        DatePicker,
    },
    data() {
        return {
            dateRange: {
                start: new Date(),
                end: new Date(),
            },
            reportSelected: '',
            results: false,
        };
    },
    watch: {
        reportSelected: function (val) {
            this.results = false;
        },
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    mounted: function () {

    },
    methods: {
        generateReport(download) {
            const self = this;
            if (!download) download = false;

            if (Tools.isEmptyString(self.reportSelected)) {
                API.showErrorNotify('Seleccione un reporte');
                return false;
            }
            API.send('POST', 'reports/download/' + self.reportSelected, {
                    download: (parseInt(download) === 1),
                    dateFrom: self.dateRange.start,
                    dateTo: self.dateRange.end,
                },
                function (response) {
                    if (typeof response.data !== 'undefined' && typeof response.data.resultFile !== 'undefined') {
                        window.open(response.data.resultFile);
                    }
                    if (typeof response.data !== 'undefined' && typeof response.data.report !== 'undefined') {
                        self.results = response.data.report;
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        }
    },
};
</script>

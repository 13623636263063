<template>
    <layout>
        <div v-if="reloadFicha" class="content-body fichaMedicaForm">
            <div class="loading-ficha my-5 p-4 text-danger text-center">
                <div class="spinner-box">
                    <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="content-body fichaMedicaForm">
            <ficha-nutricion v-if="tipoFicha === 'nutricion'" :tabDefault="tabDefault"></ficha-nutricion>
            <ficha-ginecologia v-if="tipoFicha === 'ginecologia'" :tabDefault="tabDefault"></ficha-ginecologia>
            <ficha-medicina-general v-if="tipoFicha === 'medicina_general'" :tabDefault="tabDefault"></ficha-medicina-general>
            <ficha-pediatria v-if="tipoFicha === 'pediatria'" :tabDefault="tabDefault"></ficha-pediatria>
            <ficha-urgencia v-if="tipoFicha === 'urgencia'" :tabDefault="tabDefault"></ficha-urgencia>
            <ficha-odontologia v-if="tipoFicha === 'odontologia'" :tabDefault="tabDefault"></ficha-odontologia>
            <ficha-veterinaria v-if="tipoFicha === 'veterinaria'" :tabDefault="tabDefault"></ficha-veterinaria>
            <ficha-podologia v-if="tipoFicha === 'podologia'" :tabDefault="tabDefault"></ficha-podologia>
            <ficha-traumatologia v-if="tipoFicha === 'traumatologia'" :tabDefault="tabDefault"></ficha-traumatologia>
            <ficha-urologia v-if="tipoFicha === 'urologia'" :tabDefault="tabDefault"></ficha-urologia>
            <ficha-fisioterapia v-if="tipoFicha === 'fisioterapia'" :tabDefault="tabDefault"></ficha-fisioterapia>
            <ficha-dermatologia v-if="tipoFicha === 'dermatologia'" :tabDefault="tabDefault"></ficha-dermatologia>
            <ficha-psicologia v-if="tipoFicha === 'psicologia'" :tabDefault="tabDefault"></ficha-psicologia>
            <ficha-geriatria v-if="tipoFicha === 'geriatria'" :tabDefault="tabDefault"></ficha-geriatria>
            <ficha-tanatologia v-if="tipoFicha === 'tanatologia'" :tabDefault="tabDefault"></ficha-tanatologia>
            <ficha-prenatal v-if="tipoFicha === 'prenatal'" :tabDefault="tabDefault"></ficha-prenatal>

            <div v-if="tipoFicha === '' || !tipoFicha" class="loading-ficha my-5 p-4 text-danger text-center">
                <div class="spinner-box">
                    <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";

// FICHAS MÉDICAS DISPONIBLES
import FichaGinecologia from './fichas/medico/Ginecologia/Ginecologia.vue';
import FichaNutricion from './fichas/medico/Nutricion/Nutricion.vue';
import FichaMedicinaGeneral from './fichas/medico/MedicinaGeneral/MedicinaGeneral.vue';
import FichaPediatria from './fichas/medico/Pediatria/Pediatria.vue';
import FichaUrgencia from './fichas/medico/Urgencia/Urgencia.vue';
import FichaOdontologia from './fichas/medico/Odontologia/Odontologia.vue';
import FichaPodologia from './fichas/medico/Podologia/Podologia.vue';
import FichaVeterinaria from './fichas/medico/Veterinaria/Veterinaria.vue';
import FichaTraumatologia from './fichas/medico/Traumatologia/Traumatologia.vue';
import FichaUrologia from './fichas/medico/Urologia/Urologia.vue';
import FichaFisioterapia from "./fichas/medico/Fisioterapia/Fisioterapia.vue";
import FichaDermatologia from "./fichas/medico/Dermatologia/Dermatologia.vue";
import FichaPsicologia from "./fichas/medico/Psicologia/Psicologia.vue";
import FichaGeriatria from "./fichas/medico/Geriatria/Geriatria.vue";
import FichaTanatologia from "./fichas/medico/Tanatologia/Tanatologia.vue";
import FichaPrenatal from "./fichas/medico/Prenatal/Prenatal.vue";

export default {
    components: {
        Layout,

        // fichas médicas
        FichaUrologia,
        FichaGinecologia,
        FichaNutricion,
        FichaMedicinaGeneral,
        FichaPediatria,
        FichaUrgencia,
        FichaOdontologia,
        FichaVeterinaria,
        FichaTraumatologia,
        FichaPodologia,
        FichaFisioterapia,
        FichaDermatologia,
        FichaPsicologia,
        FichaGeriatria,
        FichaTanatologia,
        FichaPrenatal
    },
    data() {
        return {
            // Ficha médica
            tabDefault: 'preclinica',
            ws:null,
            idReserva: 0,
            tipoFicha: '',
            reloadFicha: false
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            AuthGetToken: 'AuthGetToken',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {

    },
    mounted() {
        const self = this;

        let cacheTab = self.getWithExpiry('cacheTab');
        if (cacheTab != null) {
            self.tabDefault = cacheTab;
        }

        this.$store.dispatch('SetMedicoFichaActive', {}); // limpio todo antes de iniciar
        this.idReserva = this.$route.params.reservaId;
        this.loadReserva();
        console.log(self.AuthGetUserInfo);

        // Actualizar clientes
        this.emitter.on("medico-ficha-refresh", function () {
            if (self.ws && self.ws.readyState === WebSocket.OPEN) {
            //El websocket se maneja por canales de mensajes para separar los procesos
                let msg = {
                    channel: 'ficha-medica',
                    message: 'ficha-updated'
                }
                self.ws.send(JSON.stringify(msg));
                self.loadReserva();
            } else {
                console.log("No se puede enviar el mensaje, la conexión no está abierta.");
            }
        });

        // Guardar tab en cache
        this.emitter.on("save-default-tab", function (tab) {
            self.tabDefault = tab;
        });

        try {
            let token = self.AuthGetToken;
            self.ws = new WebSocket(`ws://localhost:8080?token=${token}`);
            self.ws.onopen = function(e) {
                console.log("Connection established! \n");
            };

            self.ws.onmessage = function(e) {
                console.log(e.data);
                if (e.data === "ficha-updated") {
                    self.saveCacheTab();
                    self.loadReserva();
                }
            };

            self.ws.onclose = function(e){
                console.log("Connection finished! \n");
            };

            self.ws.onerror = function(e) {
                console.log("Connection finished! \n"+ e.message);
            };
        } catch (error) {
            console.log('El servidor no ha sido encontrado');
        }
    },
    methods: {
        ...mapActions({
            SetMedicoFichaActive: 'SetMedicoFichaActive',
        }),
        dayjs,
        Numbro,
        loadReserva() {
            const self = this;
            self.personal = [];
            self.asignaciones = [];
            self.reloadFicha=true;
            API.send('GET', 'orders/metadata/by-reserva/' + self.idReserva, {}, function (response) {
                self.tipoFicha = response.data.producto.fichaMedica;
                self.emitter.emit("medico-ficha-load");
                self.$store.dispatch('SetMedicoFichaActive', response.data);
                self.reloadFicha=false;
            },
            function (response) {
                    API.showErrorNotify(response.msg);
                });
            },
        setWithExpiry(key, value, ttl) {
            const now = new Date();
            const item = {
                value: value,
                expiry: now.getTime() + ttl,
            }
            sessionStorage.setItem(key, JSON.stringify(item))
            },
        getWithExpiry(key) {
            const itemStr = sessionStorage.getItem(key)
            if (!itemStr) {
                return null
            }
            const item = JSON.parse(itemStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                sessionStorage.removeItem(key)
                return null
            }
            return item.value
        },
        saveCacheTab(){
            this.setWithExpiry('cacheTab',this.tabDefault,1 * 60 * 1000);
        }
    }
};

</script>

<style scoped>

.loading-ficha{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
</style>